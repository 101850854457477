<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />
    <div class="apipe-cloud-course-container-madeSolution">
      <div class="pc-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h1 style="margin-bottom: 15px; font-size: 42px; font-weight: 700">
            优制云人力资源管理HRM
          </h1>
          <p class="tit">
            优制云工业互联网平台倾力打造专为中小微企业设计的HRM人力资源管理系统，我们深知企业在<br />快速发展中面临招聘难、员工信息散乱、培训成本高、绩效考核繁琐等挑战。优制云HRM通过智<br />能化、一体化的管理方案，帮助企业高效整合人才资源，简化招聘流程，实现员工信息集中管<br />理；同时，提供定制化培训计划和智能绩效考核工具，降低管理成本，提升员工满意度与绩效。<br />让企业轻松驾驭人力资源，赋能企业稳健前行。
          </p>
        </div>
        <MessageInfo />
      </div>

      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="text-align: left">
          <div class="title">优制云人力资源管理HRM</div>
          <div class="titwrap">
            <p class="tit">
              整合员工信息管理、招聘、培训、绩效考核、薪酬福利等，提升企业人力资源管理效率，实现企业人才发展战略。
            </p>
          </div>
        </div>
      </div>

      <!-- 行业痛点 -->
      <div class="pc-industry d-none d-lg-block">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/hangye1.png" alt="" />
                <h6>招聘效率低</h6>
                <div class="des">
                  <div>招聘流程不规范，人才筛选困难</div>
                  <!-- <div>或库存不足</div> -->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/hangye2.png" alt="" />
                <h6>招聘成本高</h6>
                <div class="des">
                  <div>人才流失严重，招聘成本高</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/hangye3.png" alt="" />
                <h6>培训不到位</h6>
                <div class="des">
                  <div>缺乏系统性的培训计划，无法提</div>
                  <div>升员工技能</div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/hangye4.png" alt="" />
                <h6>绩效不透明</h6>
                <div class="des">
                  <div>绩效考核主观性强，缺乏客观标</div>
                  <div>准</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <div class="mobile-industry d-block d-lg-none">
        <div class="pro-tit">行业痛点</div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye1.png" alt="" />
                <h6>招聘效率低</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye2.png" alt="" />
                <h6>招聘成本高</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye3.png" alt="" />
                <h6>培训不到位</h6>
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/isc/hangye4.png" alt="" />
                <h6>绩效不透明</h6>
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 解决方案 -->
      <div class="container text-center">
        <div class="pro-tit d-none d-lg-block" style="font-size: 36px">
          解决方案
        </div>
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          解决方案
        </div>
        <img
          style="width: 100%"
          src="@/assets/newSite/hrm/solution.png"
          alt=""
        />
      </div>

      <!-- 产品能力 -->
      <div class="pc-pro-power d-none d-lg-block">
        <div class="pro-tit" style="font-size: 36px">产品能力</div>
        <div>
          <div class="tabs container text-center">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">工作台</div>
                  <div class="mcot">
                    <div>
                      以提高工作效率为目的，集中呈现待办、提醒、快捷入口以及人力资源关键数据统计结果
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    招聘需求、人力资源管理、用人部门处理、人才库
                  </div>
                  <div class="mcot">
                    <div>
                      明确招聘需求精准定位人才；人力资源管理统筹流程；用人部门处理确保岗位匹配；人才库储备资源，方便快速检索。四大功能协同，为企业高效招聘、持续发展提供有力保障。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    我的档案、入职管理、试用期管理、离职管理、员工档案、合同管理
                  </div>
                  <div class="mcot">
                    <div>
                      员工管理的六大功能为企业人力资源管理提供了有力支持。“我的档案”
                      方便员工随时查看个人信息，进行自我管理。入职管理确保新员工顺利融入。试用期管理助力新员工快速成长与适应。离职管理妥善处理员工离职事务，降低影响。合同管理规范双方权利义务，保障合法权益。六大功能协同作用，实现员工全生命周期管理，提升企业人力资源管理水平和效率。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">考勤打卡</div>
                  <div class="mcot">
                    <div>
                      支持定位、WIFI等多种方式考勤打卡，方便员工记录出勤。考勤设置丰富，涵盖考勤组管理、班次安排等多方面，可灵活定制。考勤统计能提供月度汇总、每日统计等数据，清晰呈现员工出勤情况，同时支持查看外勤轨迹。假期管理包括制定假期规则和查询员工假期余额。通过该系统，企业可高效管理员工考勤与假期，精准掌握员工工作时间与状态，提升管理效率，为企业的规范化运营提供有力保障，助力企业更好地发展。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    我的绩效、绩效考核、绩效考核进度、考核计划、绩效模板、绩效指标、绩效等级、绩效终评
                  </div>
                  <div class="mcot">
                    <div>
                      “我的绩效”
                      让员工清晰了解自身工作完成情况。通过设置绩效考核模板、指标及等级，制定科学合理的考核体系。“绩效考核进度”
                      与 “绩效考核计划” 确保考核有序推进。“绩效终评”
                      确保绩效结果合理公正性。该系统助力企业客观评估员工表现，激励员工提升工作绩效，促进企业与员工共同发展，提高企业整体竞争力，为企业的高效运营和持续发展奠定坚实基础。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 5">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">
                    首页、基础设置、社保公积金导入、薪酬计算、薪酬列表、工资条
                  </div>
                  <div class="mcot">
                    <div>
                      首页直观展示关键薪酬数据，利于管理者进行企业运营及员工管理。一键进行薪酬计算高效精准，薪酬列表清晰呈现各项明细，并支持多维度查询。工资条方便员工查看个人薪资情况。该系统助力企业规范薪酬管理流程，提高工作效率与准确性，为企业决策提供可靠依据，同时增强员工对薪酬的了解与满意度，促进企业稳定发展，营造良好的企业与员工关系氛围。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 6">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">培训计划、课程管理、培训开展、培训分析</div>
                  <div class="mcot">
                    <div>
                      培训计划与绩效及考勤等信息结合，助力企业按需有序规划培训活动。课程管理确保培训内容丰富且优质。培训开展推进并跟踪培训执行。培训分析则对培训效果进行评估与总结。该系统为企业提供一站式培训解决方案，提升员工能力与素质，促进企业持续发展，增强企业核心竞争力，为企业在激烈的市场竞争中赢得优势。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 7">
                <div class="col-xs-12 col-md-6 lf">
                  <div class="mtit">办公物品管理、积分管理、接待管理</div>
                  <div class="mcot">
                    <div>
                      办公物品管理实现对办公用品的有效管控，并支持与员工入离职相结合提高员工入离职工作高效有序开展。积分管理激励员工积极行为。接待管理确保接待工作规范高效。该系统提升企业行政效率，优化资源配置，为企业的日常运营提供有力保障，营造良好的企业氛围。
                    </div>
                  </div>
                  <div style="text-align: left">
                    <el-button
                      @click="handleTest"
                      type="primary"
                      round
                      class="btn btn-primary btn-sm"
                    >
                      免费试用
                    </el-button>
                  </div>
                </div>
                <div
                  class="col-xs-12 col-md-6"
                  style="text-align: center; padding: 10px"
                >
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 90%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-pro-power d-block d-lg-none">
        <h4 class="pro-tit">产品能力</h4>
        <div class="">
          <div class="tabs">
            <div
              :class="['items', tbx == index ? 'active' : '']"
              v-for="(item, index) in tabListNew"
              :key="index"
              @click="handleTab(index)"
            >
              <img :src="item.img1" />
              <div class="tit">{{ item.tit }}</div>
            </div>
          </div>
          <div class="tab-cot">
            <div class="container text-center">
              <div class="row" v-show="tbx == 0">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">工作台</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      以提高工作效率为目的，集中呈现待办、提醒、快捷入口以及人力资源关键数据统计结果
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>

              <div class="row" v-show="tbx == 1">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    招聘需求、人力资源管理、用人部门处理、人才库
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      明确招聘需求精准定位人才；人力资源管理统筹流程；用人部门处理确保岗位匹配；人才库储备资源，方便快速检索。四大功能协同，为企业高效招聘、持续发展提供有力保障。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 2">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    我的档案、入职管理、试用期管理、离职管理、员工档案、合同管理
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      员工管理的六大功能为企业人力资源管理提供了有力支持。“我的档案”
                      方便员工随时查看个人信息，进行自我管理。入职管理确保新员工顺利融入。试用期管理助力新员工快速成长与适应。离职管理妥善处理员工离职事务，降低影响。合同管理规范双方权利义务，保障合法权益。六大功能协同作用，实现员工全生命周期管理，提升企业人力资源管理水平和效率。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 3">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">考勤打卡</h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      支持定位、WIFI等多种方式考勤打卡，方便员工记录出勤。考勤设置丰富，涵盖考勤组管理、班次安排等多方面，可灵活定制。考勤统计能提供月度汇总、每日统计等数据，清晰呈现员工出勤情况，同时支持查看外勤轨迹。假期管理包括制定假期规则和查询员工假期余额。通过该系统，企业可高效管理员工考勤与假期，精准掌握员工工作时间与状态，提升管理效率，为企业的规范化运营提供有力保障，助力企业更好地发展。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 4">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    我的绩效、绩效考核、绩效考核进度、考核计划、绩效模板、绩效指标、绩效等级、绩效终评
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      “我的绩效”
                      让员工清晰了解自身工作完成情况。通过设置绩效考核模板、指标及等级，制定科学合理的考核体系。“绩效考核进度”
                      与 “绩效考核计划” 确保考核有序推进。“绩效终评”
                      确保绩效结果合理公正性。该系统助力企业客观评估员工表现，激励员工提升工作绩效，促进企业与员工共同发展，提高企业整体竞争力，为企业的高效运营和持续发展奠定坚实基础。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 5">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    首页、基础设置、社保公积金导入、薪酬计算、薪酬列表、工资条
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      首页直观展示关键薪酬数据，利于管理者进行企业运营及员工管理。一键进行薪酬计算高效精准，薪酬列表清晰呈现各项明细，并支持多维度查询。工资条方便员工查看个人薪资情况。该系统助力企业规范薪酬管理流程，提高工作效率与准确性，为企业决策提供可靠依据，同时增强员工对薪酬的了解与满意度，促进企业稳定发展，营造良好的企业与员工关系氛围。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 6">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    培训计划、课程管理、培训开展、培训分析
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      培训计划与绩效及考勤等信息结合，助力企业按需有序规划培训活动。课程管理确保培训内容丰富且优质。培训开展推进并跟踪培训执行。培训分析则对培训效果进行评估与总结。该系统为企业提供一站式培训解决方案，提升员工能力与素质，促进企业持续发展，增强企业核心竞争力，为企业在激烈的市场竞争中赢得优势。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
              <div class="row" v-show="tbx == 7">
                <div class="col-xs-12 col-md-6 lf" style="padding: 0 26px">
                  <h4 style="text-align: left">
                    办公物品管理、积分管理、接待管理
                  </h4>
                  <div
                    style="
                      text-align: left;
                      margin: 10px 0 10px 0;
                      font-family: Source Han Sans CN;
                      font-weight: 200;
                      color: #333333;
                      font-size: 13px;
                    "
                  >
                    <div>
                      办公物品管理实现对办公用品的有效管控，并支持与员工入离职相结合提高员工入离职工作高效有序开展。积分管理激励员工积极行为。接待管理确保接待工作规范高效。该系统提升企业行政效率，优化资源配置，为企业的日常运营提供有力保障，营造良好的企业氛围。
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="@/assets/newSite/hrm/c.png"
                    alt=""
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品优势 -->
      <div class="pc-scene d-none d-lg-block">
        <div class="pro-tit d-none d-lg-block">产品优势</div>
        <div class="container text-center">
          <div class="row row-cols-1 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="mobile-scene d-block d-lg-none">
        <div
          class="pro-tit d-block d-lg-none"
          style="font-size: 20px; font-weight: 700; margin-bottom: 20px"
        >
          产品优势
        </div>
        <div class="container text-center">
          <div class="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad1.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad2.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad3.png" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="p-3">
                <img src="@/assets/newSite/hrm/ad4.png" alt="" />
              </div>
            </div>
          </div>

          <div class="row"></div>
        </div>
      </div>

      <!-- 成功案列 -->
      <section
        class="pc-product d-none d-lg-block"
        style="margin: 80px 0 50px 0"
      >
        <div class="container text-center">
          <h1 class="pro-tit" style="margin-bottom: 20px; font-size: 36px">
            成功案例
          </h1>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div v-if="caseA.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="padding-right: 10px;">
                <h6 style="font-size: 35px">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseA.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div v-if="caseB.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">{{ caseB.title }}</h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseB.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div v-if="caseC.title" class="content" style="display: flex; padding: 20px 0">
              <div class="wrap" style="flex: 60%;padding-right: 10px;">
                <h6 style="font-size: 35px">
                  {{ caseC.title }}
                </h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    round
                    @click="
                      () => {
                        this.openNewWindow(
                          `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                        );
                      }
                    "
                    class="btn btn-primary btn-sm"
                  >
                    查看详情
                  </el-button>
                </div>
              </div>
              <div style="flex: 40%; text-align: center">
                <img
                  :src="caseC.url"
                  width="640px"
                  height="360px"
                />
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <section
        class="mobile-product d-block d-lg-none"
        style="margin-top: 40px"
      >
        <div class="container text-center">
          <h3 class="pro-tit">成功案例</h3>
          <div class="row align-items-start">
            <div
              :class="['col', idx == index ? 'acticv' : '']"
              @click="handleSwitch(index)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <span>{{ item.customer }}</span>
            </div>
          </div>
        </div>
        <div class="pro-wrap">
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 0"
          >
            <div v-if="caseA.title " class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: 700; margin-bottom: 1rem">
                  {{ caseA.title }}
                </h6>
                <p class="p-content">
                  {{ caseA.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseA.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseA.id}&tit=${caseA.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInLeftBig"
            v-show="idx == 1"
          >
            <div v-if="caseB.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 1rem">
                  {{ caseB.title }}
                </h6>
                <p class="p-content">
                  {{ caseB.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseB.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseB.id}&tit=${caseB.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div
            class="container animate__animated animate__fadeInRightBig"
            v-show="idx == 2"
          >
            <div v-if="caseC.title" class="content">
              <div style="flex: 60%">
                <h6 style="font-weight: bold; margin-bottom: 1rem">
                  {{ caseC.title }}
                </h6>
                <p class="p-content">
                  {{ caseC.description }}
                </p>
              </div>
              <div style="text-align: center; margin-bottom: 20px">
                <img :src="caseC.url" />
              </div>
              <div>
                <el-button
                  @click="
                    () => {
                      this.openNewWindow(
                        `/caseDetail?id=${caseC.id}&tit=${caseC.title}`
                      );
                    }
                  "
                  round
                  type="button"
                  class="btn btn-primary btn-sm"
                >
                  查看详情
                </el-button>
              </div>
            </div>
            <div class="noData" v-else>
              <img src="@/assets/newSite/nomore.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </section>

      <right-nav-new class="d-none d-lg-block" />
    </div>

    <NewFooter @handleClickChild="handleClickChild" />
    <MobileFooter ref="mofooter" class="d-block d-lg-none" />
  </div>
</template>
            
    <script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperCore, { Autoplay } from "swiper";
SwiperCore.use([Autoplay]);
import { caselist } from "@/api/fetch";
export default {
  name: "hardware",
  metaInfo() {
    return {
      title: "优制云HRM系统 - 人力资源管理的数字化解决方案",
      meta: [
        {
          name: "keywords",
          content:
            "人力资源管理,HRM系统,人才管理,招聘管理,员工绩效评估,SaaS软件,优制云,伟班平台",
        },
        {
          name: "description",
          content:
            "优制云HRM人力资源管理系统，通过智能化、一体化的管理方案，帮助企业高效整合人才资源，简化招聘流程，实现员工信息集中管理，让企业轻松驾驭人力资源，赋能企业稳健前行。",
        },
      ],
    };
  },
  data() {
    return {
      tabs: ["金鸿数字技术", "数研时空", "华瀚管道"],
      activeName: "1",
      idx: 0,
      tbx: 0,
      ids: -1,
      tabListNew: [
        {
          img1: require("@/assets/newSite/hrm/p1.png"),
          tit: "我的工作",
        },
        {
          img1: require("@/assets/newSite/hrm/p2.png"),
          tit: "招聘",
        },
        {
          img1: require("@/assets/newSite/hrm/p3.png"),
          tit: "员工",
        },
        {
          img1: require("@/assets/newSite/hrm/p4.png"),
          tit: "考勤",
        },
        {
          img1: require("@/assets/newSite/hrm/p5.png"),
          tit: "绩效",
        },
        {
          img1: require("@/assets/newSite/hrm/p6.png"),
          tit: "薪酬",
        },
        {
          img1: require("@/assets/newSite/hrm/p7.png"),
          tit: "培训",
        },
        {
          img1: require("@/assets/newSite/hrm/p8.png"),
          tit: "行政",
        },
      ],

      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      baseImgUrl: "https://admin.veiban.com",
      caseList: [],
      caseA: {},
      caseB: {},
      caseC: {},
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.getCase();
  },
  methods: {
    handleClickChild() {
      this.$refs.mofooter.handleDeal(2);
    },
    //成功案例
    getCase() {
      const prams = {
        pageSize: 10,
        pageNo: 1,
        newsType: 0,
        productCode: "HRM",
      };
      caselist(prams).then((res) => {
        let list = res.data.list;
          if (list.length) {
            this.caseA = list[0] || {};
            this.caseB = list[1] || {};
            this.caseC = list[2] || {};
          }
          //取三条数据 边界判断
          if (list.length > 3) {
            this.caseList = list.slice(0, 3);
          }
          if (list.length == 0) {
            this.caseList = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
          }
          if (list.length == 1) {
            let lis = [
              { customer: "暂未配置客户" },
              { customer: "暂未配置客户" },
            ];
            this.caseList = list.concat(lis);
          }
          if (list.length == 2) {
            let lis = [{ customer: "暂未配置客户" }];
            this.caseList = list.concat(lis);
          }
          if (list.length == 3) {
            this.caseList = list;
          }

      });
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({ path, id: "123456" });
      window.open(routeData.href, "_blank");
    },
    handleSwitch(idx) {
      this.idx = idx;
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    handleTab(index) {
      this.tbx = index;
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, false);
  },
};
</script>
            
    <style lang="less" scoped>
.apipe-cloud-course-container-madeSolution {
  .pc-banner {
    height: 600px;
    background-image: url("../../assets/newSite/hrm/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 18px;
        margin: 4px 0;
      }
      .exp {
        margin-top: 2rem;
        font-size: 14px;
        border: 1px solid white;
        display: inline-block;
        width: 100px;
        padding: 2px 0;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mobile-banner {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/newSite/hrm/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .title {
        font-size: 35px;
        text-align: left;
        color: white;
        font-family: Source Han Sans CN;
        font-weight: 200;
        margin-bottom: 30px;
      }
      .titwrap {
        padding: 30px 20px;
        .tit {
          font-size: 13px;
          margin: 5px 0;
        }
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }
  .pc-industry {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 40px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        background: #f6faff;
        height: 240px;
        width: 300px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      }
    }
    img {
      width: 100px;
      height: 100px;
    }
    h6 {
      margin-top: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: rgba(51, 51, 51, 1);
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .mobile-industry {
    margin: 40px 0;
    .pro-tit {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #333;
    }
    .col {
      .p-3 {
        background: #f6faff;
        margin: 10px;
      }
    }
    img {
      // width: 100px;
      // height: 100px;
    }
    h6 {
      font-family: Source Han Sans CN;
      font-weight: 200;
      color: #333333;
      font-size: 15px;
    }
    .des {
      color: #333333;
      font-size: 14px;
    }
  }
  .pc-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
    }
    .tabs {
      display: flex;
      .items {
        flex: 25%;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        .tit {
          padding: 20px 0 16px 0;
          font-size: 18px;
          font-weight: 700;
        }
        &:hover {
          cursor: pointer;
        }
        img {
          width: 36px;
          height: 36px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px;
      .row {
        min-height: 300px;
        padding: 20px 100px;
        .mtit {
          text-align: left;
          font-size: 24px;
          font-weight: 500;
        }
        .mcot {
          text-align: left;
          margin: 20px 0 30px 0;
          color: #333333;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 120px;
            background: #1484ff;
            &:hover {
              background: #66b1ff;
            }
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .mobile-pro-power {
    margin: 50px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 20px;
      color: #333;
      font-weight: 700;
    }
    .tabs {
      display: flex;
      width: 375px;
      overflow-x: scroll;
      overflow-y: hidden;
      margin: 0 auto;
      .items {
        width: 33%;
        flex-shrink: 0;
        // height: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        .tit {
          padding: 20px 0 16px 0;
          font-family: Source Han Sans CN;
          font-weight: 700;
          font-size: 16px;
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .active {
        .tit {
          border-bottom: 4px solid rgba(20, 132, 255, 1);
        }
      }
    }
    .tab-cot {
      background: #f6faff;
      padding: 20px 0;
      .row {
        min-height: 300px;
        padding: 10px;
        h4 {
          font-family: Source Han Sans CN;
          font-weight: 700;
          color: #333333;
          font-size: 16px;
        }
        .lf {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: center;
          padding: 10px;
          .btn {
            width: 100px;
          }
        }
        p {
          color: #666666;
          font-size: 14px;
        }
      }
    }
  }
  .pc-scene {
    margin: 80px 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        min-height: 200px;
        padding: 0 !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }
  .mobile-scene {
    margin: 0 0;
    .pro-tit {
      margin-bottom: 30px;
      font-size: 36px;
    }
    .col {
      .p-3 {
        padding: 5px !important;
      }
    }
    img {
      width: 100%;
    }
    h6 {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
    }
  }

  .pc-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
      }
    }
    .pro-wrap {
      background: #f6faff;
      text-align: left;
      .container {
        display: flex;

        .cot {
          background: url("../../assets/newSite/home/pc.png");
          background-repeat: no-repeat;
          background-size: cover;
          height: 702px;
          width: 100%;
        }
        img {
          // height: 16rem;
        }
        .content {
          margin: 40px 0;
          width: 100%;
          .wrap {
            flex: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-family: Source Han Sans CN;
              font-weight: 200;
              color: #333333;
              width: 540px;
              margin-bottom: 20px;
              margin-top: 12px;
              font-size: 18px;
            }
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
  .mobile-product {
    .acticv {
      span {
        height: 4px;
        border-bottom: 4px solid #1484ff;
        color: #1484ff;
      }
    }
    .col {
      padding: 1rem 0 0 0;
      cursor: pointer;
      span {
        display: inline-block;
        height: 40px;
        font-family: Source Han Sans CN;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .pro-tit {
      font-family: Source Han Sans CN;
      font-weight: 700;
      color: #333333;
      font-size: 20px;
    }
    .pro-wrap {
      text-align: left;
      background: #f6faff;
      .container {
        display: flex;
        .content {
          padding: 2rem 1.5rem 1.5rem 1.5rem;
          width: 100%;
          img {
            width: 100%;
            // height: 20rem;
          }
          .p-content {
            color: #666;
            font-size: 0.8rem;
            font-family: "思源黑体";
            line-height: 1.6rem;
          }
          .btn {
            width: 120px;
            background: #1484ff;
            height: 36px;
            color: white;
          }
        }
        .noData {
          padding: 60px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }
}
</style>
            